var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":"Campaign Matchback Results"}}),(_vm.error)?[_c('v-banner',{attrs:{"single-line":"","elevation":"10"}},[_c('v-icon',{attrs:{"slot":"icon","color":"warning","size":"36"},slot:"icon"},[_vm._v(" mdi-wifi-strength-alert-outline ")]),_c('div',[_vm._v(" There was a problem communicating to the server! "),_c('div',{staticClass:"code"},[_c('strong',[_vm._v(" "+_vm._s(_vm.error)+" ")])])])],1)]:_vm._e(),_c('span',{staticClass:"font-weight-light subtitle-1"},[_c('v-btn',{attrs:{"elevation":"1","color":"blue darken-1","dark":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" Back ")],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col')],1),(!_vm.is_sales_matchback_up_to_date)?[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',[_c('v-alert',{attrs:{"dense":"","border":"left","type":"warning"}},[_c('h2',[_vm._v("The sales matchback is not up to date. Please re-run the matchbacks to get the most accurate results.")])])],1)],1)]:_vm._e(),(!_vm.is_service_matchback_up_to_date)?[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',[_c('v-alert',{attrs:{"dense":"","border":"left","type":"warning"}},[_c('h2',[_vm._v("The service matchback is not up to date. Please re-run the matchbacks to get the most accurate results.")])])],1)],1)]:_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.salesHeaders,"items":_vm.items,"search":_vm.search,"items-per-page":50},scopedSlots:_vm._u([{key:"item.number_sales",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value))+" ")]}},{key:"item.avg_sales_gross",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.number_ros",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.customer_pay_profit",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.warranty_pay_profit",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.service_gross_profit",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.campaign_cost",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.most_recent_sale_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.most_recent_ro_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.number_mailed",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.generate_report",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.getReportInfo(item)}}},[_vm._v(" Generate Report ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.showReportDialog),callback:function ($$v) {_vm.showReportDialog=$$v},expression:"showReportDialog"}},[_c('v-card',{staticClass:"pl-2 pr-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Generate Campaign Report ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"How many were sent?"},model:{value:(_vm.numberMailed),callback:function ($$v) {_vm.numberMailed=$$v},expression:"numberMailed"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"What was the campaign cost?"},model:{value:(_vm.campaignCost),callback:function ($$v) {_vm.campaignCost=$$v},expression:"campaignCost"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.generateReport()}}},[_vm._v(" Generate ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showReportDialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingText)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }