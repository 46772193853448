<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Campaign Matchback Results"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <span
      class="font-weight-light subtitle-1"
    >
      <v-btn
        elevation="1"
        color="blue darken-1"
        dark
        @click="goBack"
      >
        <v-icon
          color="white"
        >
          mdi-keyboard-backspace
        </v-icon>
        Back
      </v-btn>
    </span>
    <v-row dense>
      <v-col>
      </v-col>
    </v-row>
    <template v-if="!is_sales_matchback_up_to_date">
      <v-row
        dense
        justify="center"
      >
        <v-col>
          <v-alert
            dense
            border="left"
            type="warning"
          >
            <h2>The sales matchback is not up to date. Please re-run the matchbacks to get the most accurate results.</h2>
          </v-alert>
        </v-col>
      </v-row>
    </template>    
    <template v-if="!is_service_matchback_up_to_date">
      <v-row
        dense
        justify="center"
      >
        <v-col>
          <v-alert
            dense
            border="left"
            type="warning"
          >
            <h2>The service matchback is not up to date. Please re-run the matchbacks to get the most accurate results.</h2>
          </v-alert>
        </v-col>
      </v-row>
    </template>            
    <v-row dense>
      <v-col>
        <v-card>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="salesHeaders"
            :items="items"
            :search="search"
            :items-per-page="50"
          >
            <template #item.number_sales="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.created_at="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template #item.avg_sales_gross="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.number_ros="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #item.customer_pay_profit="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.warranty_pay_profit="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.service_gross_profit="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.campaign_cost="{value}">
              {{ formatPrice(value) }}
            </template>
            <template #item.most_recent_sale_date="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.most_recent_ro_date="{value}">
              {{ formatDate(value) }}
            </template>
            <template #item.number_mailed="{value}">
              {{ formatNumber(value) }}
            </template>
            <template v-slot:item.generate_report="{ item }">
              <v-btn
                color="primary"
                :disabled="loading"
                @click="getReportInfo(item)"
              >
                Generate Report
              </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showReportDialog"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Generate Campaign Report
        </v-card-title>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="numberMailed"
              label="How many were sent?"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="campaignCost"
              label="What was the campaign cost?"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="generateReport()"
          >
            Generate
          </v-btn>
          <v-btn
            color="primary"
            @click="showReportDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingText }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import MailCampaignService from '@/services/MailCampaignService'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import axios from 'axios'

  export default {
    name: 'CampaignMatchbackResults',
    components: {
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        description: '',
        dialog: false,
        items: [],
        search: '',
        loading: true,
        loadingText: 'Loading',
        error: '',
        expanded: [],
        singleExpand: false,
        campaign: null,
        showReportDialog: false,
        numberMailed: 0,
        campaignCost: 0,
        matchbackResultId: null,
        is_sales_matchback_up_to_date: true,
        is_service_matchback_up_to_date: true,
        salesHeaders: [
          { text: 'Number of Sales', value: 'number_sales', align: 'center' },
          { text: 'Average Gross Sale', value: 'avg_sales_gross', align: 'center' },
          { text: 'Number of ROs', value: 'number_ros', align: 'center' },
          { text: 'Customer Pay Gross', value: 'customer_pay_profit', align: 'center' },
          { text: 'Warranty Pay Gross', value: 'warranty_pay_profit', align: 'center' },
          { text: 'Service Total Gross', value: 'service_gross_profit', align: 'center' },
          { text: 'Most Recent Sale Date', value: 'most_recent_sale_date', align: 'center' },
          { text: 'Most Recent RO Date', value: 'most_recent_ro_date', align: 'center' },
          { text: 'Number Sent', value: 'number_mailed', align: 'center' },
          { text: 'Campaign Cost', value: 'campaign_cost', align: 'center' },
          { text: 'Generate Report', value: 'generate_report', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ],
      }
    },
    computed: {
      ...mapGetters(['accountId']),
    },
    watch: {
      accountId () {
        this.loadMatchbackResults()
      },
    },
    created () {
      this.mailCampaignId = (this.$route.params.mail_campaign_id)
      this.loadMatchbackResults()
    },
    methods: {
      loadMatchbackResults: function () {
        this.items = []
        this.loading = true
        MailCampaignService.getMatchbackResults(this.mailCampaignId, this.accountId)
          .then(response => {
            this.items = [response.data].flat()
            if (this.items.length > 0) {
              this.is_sales_matchback_up_to_date = this.items[0].is_sales_matchback_up_to_date
              this.is_service_matchback_up_to_date = this.items[0].is_service_matchback_up_to_date
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      getReportInfo: function (item) {
        this.campaignCost = item.campaign_cost
        this.numberMailed = item.number_mailed
        this.matchbackResultId = item.id
        this.showReportDialog = true
      },
      generateReport: function () {
        var data = {
          mailCampaignId: this.mailCampaignId,
          numberMailed: this.numberMailed,
          campaignCost: this.campaignCost,
          matchbackResultId: this.matchbackResultId,
        }

        this.loading = true
        MailCampaignService.updateMatchbackResults(data)
          .then(response => {
            // Now download the report.
            var url = 'https://dealer-insights-api.herokuapp.com/mail_campaigns/' + this.mailCampaignId + '/download_campaign_report?aid=' + this.accountId
            this.loadingText = 'Generating Report'
            this.loading = true
            this.showReportDialog = false
            axios.get(url, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'campaign_report'
                link.click()
                URL.revokeObjectURL(link.href)
                this.loading = false
                this.loadingText = 'Loading'
              }).catch(console.error)
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this Matchback Result?') && this.items.splice(index, 1)) {
          // Call the API to update.
          var data = {
            mailCampaignId: item.mail_campaign_id,
            matchbackResultId: item.id,
          }
          this.loading = true
          MailCampaignService.deleteMatchbackResult(data)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              if (!error.response) {
                this.error = 'Network Error. Check your internet connection.'
              } else {
                var errorMessage = error.response.data.error
                if (errorMessage) {
                  this.error = errorMessage
                } else { this.error = 'The server returned an error. Please contact Dealer Insights support.' }
              }
            })
        }
      },
    },
  }
</script>
